import React from 'react'
import styled from "styled-components";

const InputBox=styled.div`
width: 208px;
height: 50px;
display:flex;
background: #FFFFFF;
border: 1px solid #DEDEDE;
box-sizing: border-box;
border-radius: 4px;
text-align:center;
align-items:center;
flex:auto;
cursor:pointer;
`;
const InputValue=styled.span`
color:#333299;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 172.96%;
flex:auto;
`;

const CustomDatePicker = React.forwardRef((props, ref) => {
    return (
        <InputBox onClick={props.onClick} ref={ref} placeholder="Select Date" className="custom-picker">
            <InputValue>{props.value}</InputValue>
            <span className="calender-img"><i className="fa fa-calendar-check-o" aria-hidden="true"></i></span>
        </InputBox>
    )
})

export default CustomDatePicker;