import React from 'react'
import participant from "../../assets/images/analytics/ico_participant.svg"
function Card(props) {
    const { title, icon, number, className, color } = props;
    return (
        <div className={className ? className + " ds-card" : "ds-card"}>
            <div className="info">
                <h3 style={color ? { color: color } : null}>{number}</h3>
                <span>{title}</span>
            </div>

            <div className="icon">
                <img src={icon ? icon : participant} alt="" />
            </div>
        </div>
    )
}

export default Card;

export function SocialCard(props) {
    const { title, icon, number, className } = props;
    return (
        <div className={className ? className + " ds-card ds-social-card" : "ds-card ds-social-card"}>
            <div className="info">
                <h3> {icon ? icon : null} {number}</h3>
                <span>{title}</span>
            </div>
        </div>
    )
}

export function StatusCard(props) {
    const { title, number, className, color, icon } = props;
    return (
        <div className={className ? className + " ds-card status-card" : "ds-card status-card"}>
            <div className="info">
                <h3 style={color ? { color: color } : null}>{number}</h3>
                <span>{title}</span>
            </div>
            {icon ?
                <div className="icon">
                    <img src={icon} alt="" />
                </div>
                : null}
        </div>
    )
}