import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import moment from "moment";
import HighchartsReact from 'highcharts-react-official';
import { Spinner } from 'evergreen-ui';

function CustomChart({ data, labels, isLoading, type, Total, ChartType }) {
    const [currentMonthData, setCurrentMonthData] = useState([])
    const [thisMonthData, setthisMonthData] = useState([])
    const [previousMonthData, setpreviousMonthData] = useState([])
    const [currentMonthYAxis, setCurrentMonthDataYAxis] = useState([])
    const [previousMonthYAxis, setpreviousMonthYAxis] = useState([])
    const [XAxisData, setXAxisData] = useState([]);

    useEffect(() => {
        setCurrentMonthData(data);
        xAxisData();
    }, [data, Total])

    useEffect(() => {
        if (thisMonthData.length > 0) {
            currentMonthYAxisData(thisMonthData)
        }

        if (previousMonthData.length > 0) {
            previousMonthYAxisData(previousMonthData)
        }
    }, [currentMonthData, Total])

    const xAxisData = () => {
        var dates = [0];
        var data = [0];
        var data2 = [0];
        var data3 = [];
        for (let I = 0; I < moment().format("D"); I++) {
            dates.push(moment().subtract(I, "days").startOf().format("D"));
            data.push(moment().subtract(I, "days").startOf().format("DD/MM/YYYY"))
            data2.push(moment().subtract(I, "days").subtract(1, "month").startOf().format("DD/MM/YYYY"))
        }
        setpreviousMonthData(data2.sort());
        setthisMonthData(data.sort());
        dates.sort((a, b) => a - b)

        dates.map((date) => {
            data3.push(date + moment().format(" MMM"));
        })
        setXAxisData(data3);
    }

    const currentMonthYAxisData = (Data) => {
        let NewData = [];
        if (currentMonthData.length > 0) {
            Data.forEach((item, index) => {
                let sameData = 0;
                if (item !== 0) {
                    currentMonthData.forEach((item2, index2) => {
                        if (item === item2) {
                            sameData++;
                        }
                    })
                }
                if (type === "absence" || type === "incomplete") { NewData.push(Math.abs(Total - sameData)); }
                else { NewData.push(sameData); }
            });
            NewData.shift(2);
            setCurrentMonthDataYAxis(NewData);
        }
    }

    const previousMonthYAxisData = (Data) => {
        let NewData = [];
        if (currentMonthData) {
            if (currentMonthData.length > 0) {
                Data.forEach((item, index) => {
                    let sameData = 0;
                    currentMonthData.forEach((item2, index2) => {
                        if (item === item2) {
                            sameData++;
                        }
                    })
                    if (type === "absence" || type === "incomplete") { NewData.push(Math.abs(Total - sameData)); }
                    else { NewData.push(sameData); }
                });
                NewData.shift();
                setpreviousMonthYAxis(NewData);
            }
        }
    }


    const options = {
        plotOptions: {
            series: {
                pointStart: 0,
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        tooltip: {
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            formatter: function () {
                return '<div style="" class="custom-tooltip">' +
                    ' <p style="color:#fff">' + this.x + moment().format(",YYYY") + '</p>' +
                    `<p style="color:#fff"> Sessions: <b>` + this.y + '</b></p></div>'
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: "",
            gridLineDashStyle: 'longdash',
            gridLineWidth: 2
        },
        xAxis: {
            title: "",
            categories: labels
        },
        series: [{
            showInLegend: false,
            type: ChartType ? ChartType : 'spline', // 'spline',
            color: "#232ed1",
            lineWidth: 3,
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 4,
                states: {
                    hover: {
                        fillColor: window.document.body.style.getPropertyValue("--accent-color"),
                        radius: 12,
                        enabled: true,
                        lineWidth: 1
                    }
                }
            },
            data: data
        }]
    }

    return (
        <>

            <div className="chart-tool">
                {isLoading ?
                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}> <Spinner size={100} /> </div>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                }
            </div>

        </>
    )
}

export default CustomChart