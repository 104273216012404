import React from 'react'
import "../../assets/css/common.css";
import logo from "../../assets/images/icon_logo.png";

function Loading() {
    return (
        <>
            <section className="loading-wrap">
                <div className="loading">
                    <div className="img">
                        <img src={logo} alt="" />
                    </div>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </section>
        </>
    )
}

export default Loading
